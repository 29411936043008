import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
// import { Spinner } from "react-bootstrap";
import AuthProvider from "./Context/AuthProvider";
import { Box } from "@mui/material";
import loading from "./Components/Vendors/Images/loading.gif";
import { useState } from "react";
import { createContext } from "react";
const Routing = React.lazy(() => import("./Components/Routing/Routing.js"));
export const RenderContext = createContext();
export const UserContext = createContext();
export const userTypeContext = createContext();
function App() {
  const [render, setRender] = useState(false);
  const [userData, setUserData] = useState({});
  const [userTypeInfo, setUserTypeInfo] = useState({});

  const decodeLocalData = (name) => {
    let storeData = window.localStorage.getItem(name);
    let result;
    if (storeData) {
      result = atob(storeData);
    } else {
      result = null;
    }
    return result ? JSON.parse(result) : null;
  };

  // useEffect(() => {
  let localData = decodeLocalData("gh_sl@g");
  // }, []);

  return (
    <div>
      <UserContext.Provider value={[userData, setUserData]}>
        <userTypeContext.Provider value={[userTypeInfo, setUserTypeInfo]}>
          <RenderContext.Provider value={[render, setRender]}>
            <AuthProvider>
              <Suspense
                fallback={
                  <Box
                    sx={{
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={loading}
                      alt="loading"
                      style={{
                        width: "200px",
                        height: "200px",
                      }}
                    />
                  </Box>
                }
              >
                <BrowserRouter>
                  <Routing />
                </BrowserRouter>
              </Suspense>
            </AuthProvider>
          </RenderContext.Provider>
        </userTypeContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
